<template>
  <span>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1920 1440"
      style="enable-background: new 0 0 1920 1440"
      xml:space="preserve"
    >
      <g id="Color_Selections_1_">
        <g id="White_xA0_Image_1_">
          <image
            style="overflow: visible"
            width="1920"
            height="1440"
            :id="'White_xA0_Image_' + $route.params.vendor_id"
            :xlink:href="getImagePath"
          >
            >
          </image>
        </g>
      </g>
      <g
        id="Social_Media_Links_1_"
        @click="addObjectView(vendor_details.object_data.D4.type)"
      >
        <g
          :id="'Rectangle_9_' + items.v_social_media_id"
          v-for="(items, index) in social_links"
          :key="items.v_social_media_id"
        >
          <g>
            <rect
              :x="index == 0 ? xb : xb + index * 45"
              y="1095"
              class="st0"
              width="32"
              height="32"
            />
          </g>
        </g>
        <g
          :id="'Layer_10_xA0_Image_1_' + items.v_social_media_id"
          v-for="(items, index) in social_links"
          :key="'sub_' + items.v_social_media_id"
        >
          <a href="javascript:;" @click="openLink(items.link)">
            <image
              style="overflow: visible"
              width="32"
              height="32"
              :id="`Layer_10_xA0_${items.v_social_media_id}_Image`"
              :x="index == 0 ? xb : xb + index * 45"
              y="1095"
              :xlink:href="`${asset}${items.name}_icon.png`"
            ></image>
          </a>
        </g>
      </g>
      <g id="Rectangle_8_1_" class="st1">
        <g id="Rectangle_8">
          <g>
            <rect
              x="355"
              y="1012"
              class="st0_transparent"
              width="245"
              height="80"
              ref="contact_image"
            />
          </g>
        </g>
      </g>
      <g id="Rectangle_6_1_" class="st1">
        <g id="Rectangle_6">
          <g>
            <rect
              x="1080"
              y="978"
              class="st0_transparent"
              width="460"
              height="146"
            />
          </g>
        </g>
      </g>
      <g
        id="Layer_8_xA0_Image_1_"
        @click="addObjectView(vendor_details.object_data.D6.type)"
      >
        <a
          href="javascript:;"
          @click="openLink(vendor_details.object_data.D6.link)"
        >
          <image
            style="overflow: visible"
            width="460"
            height="146"
            id="Layer_8_xA0_Image"
            :xlink:href="vendor_details.object_data.D6.upload_url"
            transform="matrix(1 0 0 1 1080 978)"
          ></image>
        </a>
      </g>
      <g id="Rectangle_7_1_" class="st1">
        <g id="Rectangle_7">
          <g>
            <rect
              x="1612"
              y="833"
              class="st0_transparent"
              width="200"
              height="370"
            />
          </g>
        </g>
      </g>
      <g
        id="Layer_7_xA0_Image_1_"
        @click="addObjectView(vendor_details.object_data.D5.type)"
      >
        <a
          href="javascript:;"
          @click="openLink(vendor_details.object_data.D5.link)"
        >
          <image
            style="overflow: visible"
            width="200"
            height="370"
            id="Layer_7_xA0_Image"
            :xlink:href="vendor_details.object_data.D5.upload_url"
            transform="matrix(1 0 0 1 1612 833)"
          ></image>
        </a>
      </g>
      <g id="Rectangle_5_1_" class="st1">
        <g id="Rectangle_5">
          <g>
            <rect
              x="970"
              y="688"
              class="st0_transparent"
              width="426"
              height="240"
            />
          </g>
        </g>
      </g>
      <g
        id="Layer_6_xA0_Image_1_"
        @click="addObjectView(vendor_details.object_data.D1.type)"
      >
        <a
          href="javascript:;"
          @click="ShowVideo(vendor_details.object_data.D1.upload_url)"
        >
          <image
            style="overflow: visible"
            width="426"
            height="240"
            id="Layer_6_xA0_Image"
            :xlink:href="vendor_details.object_data.D1.link"
            transform="matrix(1 0 0 1 970 688)"
          ></image>
        </a>
      </g>
      <g id="Rectangle_4_1_" class="st1">
        <g id="Rectangle_4">
          <g>
            <rect
              x="770"
              y="688"
              class="st0_transparent"
              width="180"
              height="180"
            />
          </g>
        </g>
      </g>
      <g
        id="Layer_5_xA0_Image_1_"
        @click="addObjectView(vendor_details.object_data.D3.type)"
      >
        <a
          href="javascript:;"
          @click="openLink(vendor_details.object_data.D3.link)"
        >
          <image
            style="overflow: visible"
            width="180"
            height="180"
            id="Layer_5_xA0_Image"
            :xlink:href="vendor_details.object_data.D3.upload_url"
            transform="matrix(1 0 0 1 770 688)"
          ></image>
        </a>
      </g>
      <g id="Company_Bio_1_">
        <g id="Rectangle_3" ref="desc_image">
          <g>
            <rect x="550" y="688" class="st0" width="200" height="240" />
          </g>
        </g>
        <g id="Layer_4_xA0_Image_1_"></g>
      </g>
      <g id="Rectangle_2_1_" class="st1">
        <g id="Rectangle_2">
          <g>
            <rect x="660" y="596" class="st0" width="240" height="48" />
          </g>
        </g>
      </g>
      <g id="Layer_3_xA0_Image_1_" @click="addObjectView('RESOURCE')">
        <a href="javascript:;" @click="openResources(vendor_details.vendor_id)">
          <image
            style="overflow: visible"
            width="240"
            height="48"
            id="Layer_3_xA0_Image"
            :xlink:href="asset + 'res_button.gif'"
            transform="matrix(1 0 0 1 660 596)"
          ></image>
        </a>
      </g>
      <g id="Rectangle_1_1_" class="st1">
        <g id="Rectangle_1">
          <g>
            <rect
              x="468"
              y="393"
              class="st0_transparent"
              width="432"
              height="186"
            />
          </g>
        </g>
      </g>
      <g
        id="Layer_2_xA0_Image_1_"
        @click="addObjectView(vendor_details.object_data.D2.type)"
      >
        <a
          href="javascript:;"
          @click="openLink(vendor_details.object_data.D2.link)"
        >
          <image
            style="overflow: visible"
            width="432"
            height="186"
            id="Layer_2_xA0_Image"
            :xlink:href="vendor_details.object_data.D2.upload_url"
            transform="matrix(1 0 0 1 468 393)"
          ></image>
        </a>
      </g>
    </svg>
    <span
      ref="custom_html"
      style="color: white; font-size: 0.9vw;word-break: break-word;"
      class="pl-1"
      v-if="vendor_details.comapny_desc"
      @click="addObjectView(vendor_details.object_data.D7.type)"
    >
      <span
        class="ellipsis custom_sub_html"
        ref="custom_sub_html"
        v-html="vendor_details.comapny_desc"
      ></span>
      <span
        v-if="vendor_details.comapny_desc.length > desc_size"
        class="yellow--text cursor_pointer"
        @click="show_desc_modal = true"
        v-show="descripton_overflow"
      >
        Read More
      </span>
    </span>
    <span
      v-else
      ref="custom_html"
      style="color: white; font-size: 1vw;"
      class="pl-1"
    ></span>
    <!---COntact Details -->
    <span
      ref="custom_contact_html"
      style="color: white; font-size: 0.9vw;white-space: break-spaces;line-height: 1vw;"
      class="pl-1 ellipsis"
      v-if="vendor_details.object_data.D4"
      v-html="vendor_details.object_data.D4.contact_details"
      @click="addObjectView(vendor_details.object_data.D4.type)"
    >
    </span>
    <span
      v-else
      ref="custom_contact_html"
      style="color: white; font-size: 13px;"
      class="pl-1"
    >
    </span>
    <!---COntact Details -->
    <v-dialog v-model="show_preview" max-width="50%">
      <v-card max-height="500px">
        <video
          :src="preview_data"
          height="100%"
          width="100%"
          autoplay
          v-if="isVideo(preview_data)"
          controls
          style="max-height:450px"
        ></video>
        <iframe
          frameborder="0"
          :src="preview_data"
          v-else
          style="width:100%;height:500px;max-height:500px"
          allow="autoplay;"
          allowfullscreen
        ></iframe>
        <v-btn
          style="position:absolute;top:10px;right:10px"
          color="red"
          dark
          fab
          small
          @click="show_preview = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="show_desc_modal" max-width="50%">
      <v-card>
        <v-card-title class="pa-3 pb-1">Company Description</v-card-title>
        <v-card-text
          class="pa-3 pb-0 rounded-lg"
          v-html="vendor_details.comapny_desc"
          style="white-space: pre-wrap"
        >
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="show_desc_modal = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ResourcePopup />
  </span>
</template>
<script>
import axios from "@/helper/axios";
import ResourcePopup from "@/components/Resource.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "MainImage",
  components: {
    ResourcePopup,
  },
  data() {
    return {
      loading: false,
      preview_data: null,
      show_preview: false,
      show_desc_modal: false,
      respresentative: [],
      desc_y: 0,
      xb: 350,
      booth_image: null,
      desc_size: 180,
      descripton_overflow: false,
    };
  },
  watch: {
    show_preview: function(value) {
      if (!value) {
        this.setPopup(false);
        this.preview_data = null;
      }
    },
  },
  computed: {
    ...mapState("utils", ["maxWidth"]),
    ...mapState("resource", ["resource_vendor_id", "resource_start_from"]),
    ...mapState("vendor", ["vendor_details", "show_intro_popup"]),
    asset() {
      return process.env.VUE_APP_ASSETS;
    },
    intro_video_thumb() {
      return process.env.VUE_APP_ASSETS + "intro_video_thumb.png";
    },
    social_links() {
      return this.vendor_details.social_links.filter((item) => {
        return item.link;
      });
    },
    getImagePath() {
      const booth_id = this.$route.params.booth_id;
      console.log(booth_id, "booth_id");

      const imagePath = require(`../../assets/PCM_Booth_Diamond_people_version_${booth_id}_4x3.png`);
      return imagePath;
    },
  },
  methods: {
    ...mapMutations("resource", ["setResourceData"]),
    ...mapMutations("vendor", ["setPopup"]),
    addObjectView(type) {
      let _self = this;
      axios
        .post("/object/add_view", {
          type: type,
          vendor_id: this.$route.params.vendor_id,
        })
        .then(function() {})
        .catch(function(e) {
          console.log(e);
        });
      _self.loading = false;
    },
    openLink(href) {
      if (href) {
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href,
        }).click();
      }
    },
    isShowReadMore(description) {
      return Math.round(description.length / 20) > 12;
    },
    openResources(vendor_id) {
      this.setResourceData({
        key: "show_resource_modal",
        data: true,
      });
      this.setResourceData({
        key: "resource_vendor_id",
        data: vendor_id,
      });
    },
    getCoords(elem) {
      // crossbrowser version
      var box = elem.getBoundingClientRect();
      var body = document.getElementsByTagName("svg")[0];
      var docEl = body;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top = box.top + scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;
      var width = box.width;
      var height = box.height;

      return {
        top: Math.round(top),
        left: Math.round(left),
        width: Math.round(width),
        height: Math.round(height),
      };
    },
    setWidthOfCustomHtml() {
      let desc_image = this.$refs["desc_image"];
      let custom_html = this.$refs["custom_html"];
      let custom_sub_html = this.$refs["custom_sub_html"];
      let bounds = this.getCoords(desc_image);
      custom_html.style.position = "absolute";
      custom_html.style.left = bounds.left + "px";
      custom_html.style.top = bounds.top + "px";
      custom_html.style.width = bounds.width + "px";
      custom_html.style.height = bounds.height + "px";
      if (custom_sub_html) {
        custom_sub_html.style.height = bounds.height + "px";
      }
      this.desc_size = bounds.height;
    },
    setWidthOfCustomContact() {
      let desc_image = this.$refs["contact_image"];
      let custom_html = this.$refs["custom_contact_html"];
      let bounds = this.getCoords(desc_image);
      custom_html.style.position = "absolute";
      custom_html.style.left = bounds.left + "px";
      custom_html.style.top = bounds.top + "px";
      custom_html.style.width = bounds.width + "px";
      custom_html.style.height = bounds.height + "px";
      this.desc_size = bounds.height;
    },
    isVideo(link) {
      if (this.preview_data) {
        let url = link.split(".");
        url = url[url.length - 1];
        let suportedVideos = ["mp4", "webm"];
        if (suportedVideos.includes(url)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    ShowVideo(video_src) {
      this.show_preview = true;
      this.preview_data = video_src;
    },
    checkOverflow() {
      let el = this.$refs.custom_sub_html;
      var curOverflow = el.style.overflow;
      if (!curOverflow || curOverflow === "visible")
        el.style.overflow = "hidden";
      var isOverflowing =
        el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
      if (isOverflowing) {
        this.descripton_overflow = true;
      }
    },
  },
  mounted() {
    this.setWidthOfCustomHtml();
    this.setWidthOfCustomContact();
    this.checkOverflow();
    window.onresize = () => {
      this.setWidthOfCustomHtml();
      this.setWidthOfCustomContact();
      this.checkOverflow();
    };
  },
  updated: function() {
    this.$nextTick(function() {
      if (this.show_intro_popup) {
        this.ShowVideo(this.vendor_details.object_data.D1.upload_url);
      }
    });
  },
};
</script>
<style type="text/css">
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #00000069 !important;
}
.st0_transparent {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #0db04b00;
}
.st0_contact {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #727272;
}
.st1 {
  enable-background: new;
}
</style>
